import { DOCUMENT } from '@angular/common';
import { Injectable, inject } from '@angular/core';
import { createContextLogger } from '@konnektu/helpers';
import { BehaviorSubject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class ThemeService {
  private readonly logger = createContextLogger('ThemeService');

  private readonly document = inject(DOCUMENT);

  private readonly _theme$ = new BehaviorSubject<'Light' | 'Dark'>('Light');

  constructor() {
    this.logger.info('Initializing ThemeService');
    const themeInLocalStorage = localStorage.getItem('theme');

    if (themeInLocalStorage === 'Dark') {
      this.document.firstElementChild?.classList.add('dark');
    } else {
      this.document.firstElementChild?.classList.remove('dark');
    }

    if (themeInLocalStorage === 'Light' || themeInLocalStorage === 'Dark') {
      this._theme$.next(themeInLocalStorage);
      this.document.firstElementChild?.setAttribute(
        'data-theme',
        themeInLocalStorage
      );
    } else {
      this.document.firstElementChild?.setAttribute('data-theme', 'Light');
      localStorage.setItem('theme', 'Light');
    }
    this._theme$.subscribe((theme) => {
      this.document.firstElementChild?.setAttribute('data-theme', theme);

      if (theme === 'Dark') {
        this.document.firstElementChild?.classList.add('dark');
      } else {
        this.document.firstElementChild?.classList.remove('dark');
      }

      localStorage.setItem('theme', theme);
    });
  }

  public get current(): 'Light' | 'Dark' {
    return this._theme$.getValue();
  }

  public toggleTheme(): void {
    this._theme$.next(this.current === 'Light' ? 'Dark' : 'Light');
  }
}
