<tui-breadcrumbs size="l">
  @for (item of crumbs$ | async; track $index) {
    @if (item.template) {
      <a *tuiItem tuiLink class="no-cursor">
        <ng-container
          [ngTemplateOutlet]="item.template"
          [ngTemplateOutletContext]="{ $implicit: item.label }"
        ></ng-container>
      </a>
    } @else if (item.link) {
      <a *tuiItem tuiLink [routerLink]="item.link"  [class.no-cursor]="$last" class="max-w-[300px] truncate">
        {{ item.label | translate }}
      </a>
    } @else {
      <a *tuiItem tuiLink class="no-cursor">
        {{ item.label | translate }}
      </a>
    }
  }
</tui-breadcrumbs>
<div class="actions">
  @if (isFilterToggleEnabled()) {
    <div class="filter-toggle tui-space_right-2">
      <tui-toggle [ngModel]="isFilterVisible()" (ngModelChange)="updateFilterVisibility($event)" size="m"></tui-toggle>
      <span class="tui-space_left-2">{{ 'topBar.toggleFilterVisibility' | translate }}</span>
    </div>
  }

  @if (actionTemplate(); as template) {
    <ng-container [ngTemplateOutlet]="template"></ng-container>
  }
</div>
