import {
  Injectable,
  Injector,
  TemplateRef,
  inject,
  runInInjectionContext
} from '@angular/core';
import {
  ActivatedRouteSnapshot,
  Data,
  NavigationEnd,
  RouteConfigLoadEnd,
  Router,
  Scroll
} from '@angular/router';
import { TuiContextWithImplicit } from '@taiga-ui/cdk';
import { BehaviorSubject, filter } from 'rxjs';

export interface Breadcrumb {
  label: string;
  link: string | null;
  template?: TemplateRef<TuiContextWithImplicit<string>>;
}

@Injectable({ providedIn: 'root' })
export class BreadcrumbService {
  private readonly router = inject(Router);

  private readonly injector = inject(Injector);

  private readonly _breadcrumbs$ = new BehaviorSubject<Breadcrumb[]>([]);

  readonly breadcrumbs$ = this._breadcrumbs$.asObservable();

  protected lastCrumbTemplate?: TemplateRef<TuiContextWithImplicit<string>>;

  constructor() {
    this.router.events
      .pipe(
        filter(
          (event) =>
            event instanceof NavigationEnd ||
            event instanceof RouteConfigLoadEnd ||
            (event instanceof Scroll &&
              event.routerEvent instanceof NavigationEnd)
        )
      )
      .subscribe(() => {
        // Emit the new hierarchy
        this._breadcrumbs$.next(this.constructBreadcrumbHierarchy());
      });
  }

  constructBreadcrumbHierarchy(): Breadcrumb[] {
    const root = this.router.routerState.snapshot.root;
    const breadcrumbs: Breadcrumb[] = [];
    this.addBreadcrumb(root, [], breadcrumbs);

    if (this.lastCrumbTemplate) {
      const lastCrumb = breadcrumbs.pop();
      if (lastCrumb) {
        breadcrumbs.push({
          ...lastCrumb,
          template: this.lastCrumbTemplate
        });
      }
    }
    return breadcrumbs;
  }

  useTemplateForLastCrumb(
    template: TemplateRef<TuiContextWithImplicit<string>>
  ) {
    this.lastCrumbTemplate = template;
    this._breadcrumbs$.next(this.constructBreadcrumbHierarchy());
  }

  deuseTemplateForLastCrumb() {
    delete this.lastCrumbTemplate;
  }

  private addBreadcrumb(
    route: ActivatedRouteSnapshot,
    parentUrl: string[],
    breadcrumbs: Breadcrumb[]
  ) {
    if (route) {
      const routeUrl = parentUrl.concat(route.url.map((url) => url.path));

      if (route.data['breadcrumb']) {
        const breadcrumb = this.getCrumb(route.data, routeUrl);
        breadcrumbs.push(breadcrumb);
      }

      if (route.firstChild) {
        this.addBreadcrumb(route.firstChild, routeUrl, breadcrumbs);
      }
    }
  }

  private getCrumb(data: Data, routeUrl: string[]): Breadcrumb {
    const crumbData = data['breadcrumb'] as
      | string
      | ((data: Data) => { label: string; link: string | null } | string)
      | { label: string; link: string | null };
    if (typeof crumbData === 'function') {
      const computedCrumb = runInInjectionContext(this.injector, () =>
        crumbData(data)
      );
      if (typeof computedCrumb === 'string') {
        return {
          label: computedCrumb,
          link: '/' + routeUrl.join('/')
        };
      }
      return computedCrumb;
    }
    if (typeof crumbData === 'string') {
      return {
        label: crumbData,
        link: '/' + routeUrl.join('/')
      };
    } else {
      return crumbData;
    }
  }
}
