import { CommonModule } from '@angular/common';
import { Component, inject } from '@angular/core';
import { RouterModule } from '@angular/router';
import { LayoutService } from '../layout.service';
import { SidebarModule } from '../sidebar/sidebar.module';
import { SidebarService } from '../sidebar/sidebar.service';
import { TopBarService } from '../top-bar.service';
import { TopBarComponent } from '../top-bar/top-bar.component';

@Component({
  selector: 'knk-sidebar-layout',
  templateUrl: 'sidebar-layout.component.html',
  standalone: true,
  imports: [CommonModule, RouterModule, SidebarModule, TopBarComponent],
  styleUrls: ['sidebar-layout.component.scss']
})
export class LayoutComponent {
  private readonly sidebarService = inject(SidebarService);

  private readonly topBarService = inject(TopBarService);

  protected readonly layoutService = inject(LayoutService);

  isMenuExpanded$ = this.sidebarService.isSidenavExpanded$;

  isTopBarVisible = this.topBarService.isTopBarVisible;
}
