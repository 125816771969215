import * as i0 from '@angular/core';
import { forwardRef, TemplateRef, Component, ChangeDetectionStrategy, Inject, Input, HostBinding, ContentChildren, NgModule } from '@angular/core';
import { tuiCreateToken, tuiProvideOptions, EMPTY_QUERY, TuiItemDirective, TuiItemModule } from '@taiga-ui/cdk';
import * as i1 from '@taiga-ui/core';
import { TuiModeDirective, TuiSvgModule } from '@taiga-ui/core';
import * as i2 from '@angular/common';
import { CommonModule } from '@angular/common';
function TuiBreadcrumbsComponent_ng_container_0_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementContainer(0);
  }
}
function TuiBreadcrumbsComponent_ng_container_2_tui_svg_2_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelement(0, "tui-svg", 4);
  }
  if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext(2);
    i0.ɵɵproperty("src", ctx_r0.options.icon);
  }
}
function TuiBreadcrumbsComponent_ng_container_2_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementContainerStart(0);
    i0.ɵɵelementContainer(1, 2);
    i0.ɵɵtemplate(2, TuiBreadcrumbsComponent_ng_container_2_tui_svg_2_Template, 1, 1, "tui-svg", 3);
    i0.ɵɵelementContainerEnd();
  }
  if (rf & 2) {
    const item_r2 = ctx.$implicit;
    const last_r3 = ctx.last;
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngTemplateOutlet", item_r2);
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", !last_r3);
  }
}
const TUI_BREADCRUMBS_DEFAULT_OPTIONS = {
  icon: 'tuiIconChevronRight',
  size: 'm',
  mode: 'onLight'
};
const TUI_BREADCRUMBS_OPTIONS = tuiCreateToken(TUI_BREADCRUMBS_DEFAULT_OPTIONS);
function tuiBreadcrumbsOptionsProvider(options) {
  return tuiProvideOptions(TUI_BREADCRUMBS_OPTIONS, options, TUI_BREADCRUMBS_DEFAULT_OPTIONS);
}
class TuiBreadcrumbsComponent extends TuiModeDirective {
  constructor(options) {
    super();
    this.options = options;
    this.size = this.options.size;
    this.items = EMPTY_QUERY;
    this.mode = this.options.mode;
  }
}
TuiBreadcrumbsComponent.ɵfac = function TuiBreadcrumbsComponent_Factory(t) {
  return new (t || TuiBreadcrumbsComponent)(i0.ɵɵdirectiveInject(TUI_BREADCRUMBS_OPTIONS));
};
TuiBreadcrumbsComponent.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: TuiBreadcrumbsComponent,
  selectors: [["tui-breadcrumbs"]],
  contentQueries: function TuiBreadcrumbsComponent_ContentQueries(rf, ctx, dirIndex) {
    if (rf & 1) {
      i0.ɵɵcontentQuery(dirIndex, TuiItemDirective, 4, TemplateRef);
    }
    if (rf & 2) {
      let _t;
      i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.items = _t);
    }
  },
  hostVars: 1,
  hostBindings: function TuiBreadcrumbsComponent_HostBindings(rf, ctx) {
    if (rf & 2) {
      i0.ɵɵattribute("data-size", ctx.size);
    }
  },
  inputs: {
    size: "size"
  },
  features: [i0.ɵɵProvidersFeature([{
    provide: TuiModeDirective,
    useExisting: forwardRef(() => TuiBreadcrumbsComponent)
  }]), i0.ɵɵInheritDefinitionFeature],
  decls: 3,
  vars: 4,
  consts: [[4, "ngIf"], [4, "ngFor", "ngForOf"], [3, "ngTemplateOutlet"], ["class", "t-icon", 3, "src", 4, "ngIf"], [1, "t-icon", 3, "src"]],
  template: function TuiBreadcrumbsComponent_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵtemplate(0, TuiBreadcrumbsComponent_ng_container_0_Template, 1, 0, "ng-container", 0);
      i0.ɵɵpipe(1, "async");
      i0.ɵɵtemplate(2, TuiBreadcrumbsComponent_ng_container_2_Template, 3, 2, "ng-container", 1);
    }
    if (rf & 2) {
      i0.ɵɵproperty("ngIf", i0.ɵɵpipeBind1(1, 2, ctx.items.changes));
      i0.ɵɵadvance(2);
      i0.ɵɵproperty("ngForOf", ctx.items);
    }
  },
  dependencies: [i1.TuiSvgComponent, i2.NgIf, i2.NgForOf, i2.NgTemplateOutlet, i2.AsyncPipe],
  styles: ["[_nghost-%COMP%]{display:flex;align-items:center;height:2.5rem;white-space:nowrap;font:var(--tui-font-text-s);color:var(--tui-text-01)}[data-size=l][_nghost-%COMP%]{font:var(--tui-font-text-m)}.t-icon[_ngcontent-%COMP%]{margin:0 .25rem;opacity:.4}"],
  changeDetection: 0
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TuiBreadcrumbsComponent, [{
    type: Component,
    args: [{
      selector: 'tui-breadcrumbs',
      templateUrl: './breadcrumbs.template.html',
      styleUrls: ['./breadcrumbs.style.less'],
      changeDetection: ChangeDetectionStrategy.OnPush,
      providers: [{
        provide: TuiModeDirective,
        useExisting: forwardRef(() => TuiBreadcrumbsComponent)
      }]
    }]
  }], function () {
    return [{
      type: undefined,
      decorators: [{
        type: Inject,
        args: [TUI_BREADCRUMBS_OPTIONS]
      }]
    }];
  }, {
    size: [{
      type: Input
    }, {
      type: HostBinding,
      args: ['attr.data-size']
    }],
    items: [{
      type: ContentChildren,
      args: [TuiItemDirective, {
        read: TemplateRef
      }]
    }]
  });
})();
class TuiBreadcrumbsModule {}
TuiBreadcrumbsModule.ɵfac = function TuiBreadcrumbsModule_Factory(t) {
  return new (t || TuiBreadcrumbsModule)();
};
TuiBreadcrumbsModule.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
  type: TuiBreadcrumbsModule
});
TuiBreadcrumbsModule.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
  imports: [[CommonModule, TuiSvgModule, TuiItemModule]]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TuiBreadcrumbsModule, [{
    type: NgModule,
    args: [{
      imports: [CommonModule, TuiSvgModule, TuiItemModule],
      declarations: [TuiBreadcrumbsComponent],
      exports: [TuiBreadcrumbsComponent, TuiItemDirective]
    }]
  }], null, null);
})();

/**
 * Generated bundle index. Do not edit.
 */

export { TUI_BREADCRUMBS_DEFAULT_OPTIONS, TUI_BREADCRUMBS_OPTIONS, TuiBreadcrumbsComponent, TuiBreadcrumbsModule, tuiBreadcrumbsOptionsProvider };
