import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { TranslatePipe } from '@konnektu/helpers';
import {
  iconAI,
  iconCertificate,
  iconCheck,
  iconChoose,
  iconClock,
  iconCommunication,
  IconComponent,
  iconCustomerCard,
  iconExtension,
  iconFlow,
  iconList,
  iconLogo,
  iconSearch,
  iconSegments,
  iconSettings,
  iconSettingsVertical,
  iconTech,
  iconWait,
  provideIcons,
  provideIconsConfig
} from '@konnektu/icons';
import { TuiSvgModule } from '@taiga-ui/core';
import { MenuItemComponent } from './menu-item/menu-item.component';
import { SidebarComponent } from './sidebar.component';

@NgModule({
  declarations: [SidebarComponent],
  imports: [
    CommonModule,
    IconComponent,
    TuiSvgModule,
    RouterModule,
    TranslatePipe,
    MenuItemComponent
  ],
  providers: [
    provideIcons([
      iconAI,
      iconTech,
      iconList,
      iconCustomerCard,
      iconChoose,
      iconCheck,
      iconCertificate,
      iconWait,
      iconFlow,
      iconSegments,
      iconCommunication,
      iconClock,
      iconSettings,
      iconExtension,
      iconSearch,
      iconLogo,
      iconSettingsVertical
    ]),
    provideIconsConfig({ defaultSize: 'md' })
  ],
  exports: [SidebarComponent]
})
export class SidebarModule {}
