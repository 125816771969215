import { CommonModule } from '@angular/common';
import { Component, Injector } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { SettingService } from './settings.service';

@Component({
  selector: 'knk-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss'],
  standalone: true,
  imports: [CommonModule, TranslateModule]
})
export class SettingsComponent {
  constructor(
    public settingsService: SettingService,
    public readonly injector: Injector
  ) {}
}
