import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { TuiLinkModule } from '@taiga-ui/core';
import { TuiBreadcrumbsModule, TuiToggleModule } from '@taiga-ui/kit';
import { BreadcrumbService } from '../breadcrumb.service';
import { TopBarService } from '../top-bar.service';

@Component({
  selector: 'knk-top-bar',
  standalone: true,
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    TranslateModule,
    TuiBreadcrumbsModule,
    TuiToggleModule,
    TuiLinkModule
  ],
  templateUrl: './top-bar.component.html',
  styleUrls: ['./top-bar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TopBarComponent {
  private readonly breadcrumbService = inject(BreadcrumbService);

  private readonly topBarService = inject(TopBarService);

  protected readonly actionTemplate = toSignal(
    this.topBarService.actionTemplate$
  );

  protected readonly isFilterToggleEnabled =
    this.topBarService.isFilterToggleEnabled;

  protected readonly isFilterVisible = this.topBarService.isFilterVisible;

  crumbs$ = this.breadcrumbService.breadcrumbs$;

  updateFilterVisibility(visible: boolean) {
    this.isFilterVisible.update(() => visible);
  }
}
