<div routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }" [routerLink]="item.route" class="menu-item">
  <span class="icon">
    <tui-svg [src]="item.icon ?? 'knkUndefinedIcon'"></tui-svg>
  </span>

  <span class="label">
    {{ item.label | translate }}
  </span>

  <tui-svg *ngIf="item.children && item.children.length > 0" class="chevron" src="tuiIconChevronRight"></tui-svg>
</div>
