import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  OnInit,
  ViewChild,
  inject
} from '@angular/core';
import { Router } from '@angular/router';
import { AcsService } from '@konnektu/acs-data';
import { DestroyService, createContextLogger } from '@konnektu/helpers';
import { BehaviorSubject, map, switchMap, take, takeUntil } from 'rxjs';
import { SettingService } from '../settings';
import { SettingsComponent } from '../settings/settings.component';
import { SidebarService, SidenavItem } from './sidebar.service';

@Component({
  selector: 'knk-sidebar',
  templateUrl: 'sidebar.component.html',
  styleUrls: ['sidebar.component.scss'],
  providers: [DestroyService],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SidebarComponent implements OnInit {
  @ViewChild('settings') public settingsElementRef!: ElementRef;

  private settingOverlay: OverlayRef | null = null;

  private readonly acsService = inject(AcsService);

  private readonly logger = createContextLogger('SidenavComponent');

  isSidenavCollapsed$ = this.sidenavService.isSidenavCollapsed$;

  currentItemsChecking$ = new BehaviorSubject(false);

  currentItems$ = this.sidenavService.currentMenuItems$.pipe(
    switchMap(({ items, path }) => {
      this.currentItemsChecking$.next(true);
      return this.acsService
        .hasAccess(
          items.map(({ id }) => ({
            Ui: { Section: `MenuItem::${id}` }
          }))
        )
        .pipe(
          map((access) => {
            this.currentItemsChecking$.next(false);
            return {
              items: items.filter((_, index) => access[index]),
              path
            };
          })
        );
    })
  );

  currentPathArray$ = this.sidenavService.currentMenuPath$;

  customLogo = this.settingsService.customLogo;

  constructor(
    private overlay: Overlay,
    private settingsService: SettingService,
    private readonly router: Router,
    private readonly destroy$: DestroyService,
    private readonly sidenavService: SidebarService
  ) {}

  ngOnInit(): void {
    this.settingsService.settingsOpened$
      .pipe(takeUntil(this.destroy$))
      .subscribe((v) => (v ? this.openSettings() : this.closeSettings()));
  }

  forwardInMenu(entry: SidenavItem): void {
    this.sidenavService.menuOpenItem(entry.id);
  }

  backInMenu(): void {
    this.sidenavService.menuBack();
  }

  toggleStateSidenavState(): void {
    this.sidenavService.menuToggleCollapse();
  }

  routeLinkSelect(item: SidenavItem): void {
    const isMenuOpened = !this.sidenavService.isSidenavCollapsed$.getValue();
    if (isMenuOpened) {
      this.logger.debug('Resolving menu item action', item);
      if (item.route) {
        void this.router.navigateByUrl(`/${item.route}`);
      }
    } else {
      this.sidenavService.menuExpand();
    }
  }

  openSettings(): void {
    if (this.settingOverlay) {
      return;
    }

    this.settingOverlay = this.overlay.create({
      hasBackdrop: true,
      positionStrategy: this.overlay
        .position()
        .flexibleConnectedTo(this.settingsElementRef)
        .withPositions([
          {
            originX: 'end',
            originY: 'top',
            overlayX: 'start',
            overlayY: 'bottom'
          }
        ])
    });

    this.settingOverlay
      .backdropClick()
      .pipe(take(1))
      .subscribe(() => this.closeSettings());

    const settingsPortal = new ComponentPortal(SettingsComponent);
    this.settingOverlay.attach(settingsPortal);
  }

  closeSettings(): void {
    if (!this.settingOverlay) {
      return;
    }
    this.settingOverlay.dispose();
    this.settingOverlay = null;
  }

  redirectToMainPage(): void {
    this.sidenavService.redirectToMain();
  }
}
