import {
  DestroyRef,
  Injectable,
  TemplateRef,
  inject,
  signal
} from '@angular/core';
import { TuiContextWithImplicit } from '@taiga-ui/cdk';
import { BehaviorSubject } from 'rxjs';
import { BreadcrumbService } from './breadcrumb.service';

export interface TopBarConfig {
  isVisible?: boolean;
  actionTemplate?: TemplateRef<void>;
  isFilterToggleEnabled?: boolean;
  customBreadcrumb?: TemplateRef<TuiContextWithImplicit<string>>;
}

@Injectable({ providedIn: 'root' })
export class TopBarService {
  private readonly breadcrumbService = inject(BreadcrumbService);

  isTopBarVisible = signal(true);

  isFilterToggleEnabled = signal(false);

  isFilterVisible = signal(false);

  actionTemplate$ = new BehaviorSubject<TemplateRef<unknown> | null>(null);

  configureTopBar(config: TopBarConfig, destroy: DestroyRef) {
    this.resetTopBar();
    this.isTopBarVisible.update(() => config.isVisible ?? true);
    this.actionTemplate$.next(config.actionTemplate ?? null);
    this.isFilterToggleEnabled.update(
      () => config.isFilterToggleEnabled ?? false
    );
    if (config.customBreadcrumb) {
      this.breadcrumbService.useTemplateForLastCrumb(config.customBreadcrumb);
    }

    destroy.onDestroy(() => this.resetTopBar());
  }

  resetTopBar() {
    this.isTopBarVisible.update(() => true);
    this.isFilterToggleEnabled.update(() => false);
    this.actionTemplate$.next(null);
    this.isFilterVisible.update(() => false);
    this.breadcrumbService.deuseTemplateForLastCrumb();
  }
}
