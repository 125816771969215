<div class="knk-settings">
  <div
    class="knk-settings__item"
    *ngFor="let settingItem of settingsService.currentSettings | async"
    (click)="settingItem.action && settingItem.action(injector)"
  >
    <span *ngIf="settingItem" class="knk-settings__item-caption">
      {{ settingItem.caption | translate }}
    </span>
  </div>
</div>
