import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { TuiSvgModule } from '@taiga-ui/core';
import { SidenavItem } from '../sidebar.service';

@Component({
  selector: 'knk-menu-item',
  standalone: true,
  imports: [CommonModule, RouterModule, TranslateModule, TuiSvgModule],
  templateUrl: './menu-item.component.html',
  styleUrls: ['./menu-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MenuItemComponent {
  @Input({ required: true }) item!: SidenavItem;
}
