<aside class="knk-menu open" [class.expanded]="(isSidenavCollapsed$ | async) === false">
  <div (click)="redirectToMainPage()" class="knk-menu__logo">
    <div class="knk-menu__logo-holder" *ngIf="!(customLogo.extendedImageUrl || customLogo.shrinkedImageUrl)">
      <span class="knk-menu__logo-text">Konnekt</span>
      <tui-svg src="knkLogo" class="knk-menu__logo-icon"></tui-svg>
    </div>

    <img
      *ngIf="customLogo.extendedImageUrl && customLogo.shrinkedImageUrl"
      class="knk-menu__custom-logo"
      [ngStyle]="customLogo.additionalStyles"
      [src]="(isSidenavCollapsed$ | async) ? customLogo.shrinkedImageUrl : customLogo.extendedImageUrl"
    />
  </div>

  <nav class="knk-menu__items" *ngIf="currentItems$ | async as currentItems; else loadingTemplate">
    <ng-container *ngIf="(currentItemsChecking$ | async) === false; else loadingTemplate">
      <knk-menu-item
        *ngIf="currentItems?.path?.length"
        (click)="backInMenu()"
        [item]="{ id: 'backItem', label: 'common.back', icon: 'tuiIconChevronLeft' }"
      >
      </knk-menu-item>

      <knk-menu-item
        *ngFor="let entry of currentItems?.items"
        [item]="entry"
        (click)="entry.children?.length ? forwardInMenu(entry) : routeLinkSelect(entry)"
      ></knk-menu-item>
    </ng-container>
  </nav>

  <div #settings (click)="openSettings()" class="knk-menu__settings-item">
    <knk-menu-item [item]="{ label: 'menu.entry.settings', icon: 'knkSettings', id: 'settingsItem' }"></knk-menu-item>
  </div>

  <knk-menu-item
    (click)="toggleStateSidenavState()"
    [item]="{
      label: 'menu.entry.collapseSidebar',
      icon: (isSidenavCollapsed$ | async) ? 'tuiIconChevronsRight' : 'tuiIconChevronsLeft',
      id: 'collapseItem'
    }"
  ></knk-menu-item>

  <ng-template #loadingTemplate>
    <div class="knk-menu__loading">
      <knk-icon key="loading"></knk-icon>
    </div>
  </ng-template>
</aside>
