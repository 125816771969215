<div class="root">
  <div class="sidenav" [class.expanded]="isMenuExpanded$ | async">
    <knk-sidebar></knk-sidebar>
  </div>
  <div class="content">
    @if (isTopBarVisible()) {
      <knk-top-bar></knk-top-bar>
    }
    <div class="route" [ngClass]="{ padded: layoutService.isInnerPaddingEnabled() }">
      <router-outlet></router-outlet>
    </div>
  </div>
</div>
