import { Injectable, Injector, OnDestroy } from '@angular/core';
import { AuthService } from '@konnektu/auth';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, Subscription } from 'rxjs';
import { ThemeService } from '../theme.service';

export interface SettingItem {
  caption: string;
  action?: (injector: Injector) => void;
}

@Injectable({ providedIn: 'root' })
export class SettingService implements OnDestroy {
  public readonly defaultItems: SettingItem[] = [
    {
      caption: 'common.changeLanguage',
      action: (): void => {
        if (this.translation.currentLang === 'ru') {
          this.translation.use('en');
        } else {
          this.translation.use('ru');
        }
        this.settingsOpened$.next(false);
      }
    },
    {
      caption: 'common.logout',
      action: (): void => {
        this.authService.logout();
        this.settingsOpened$.next(false);
      }
    }
  ];

  customLogo: {
    shrinkedImageUrl: string | null;
    extendedImageUrl: string | null;
    additionalStyles?: {
      [kStyle: string]: any;
    };
  } = {
    shrinkedImageUrl: null,
    extendedImageUrl: null
  };

  private subscriptions: Subscription = new Subscription();

  public settingsOpened$: BehaviorSubject<boolean> = new BehaviorSubject(false);

  private registeredSettings: SettingItem[] = [...this.defaultItems];

  currentSettings: BehaviorSubject<SettingItem[]> = new BehaviorSubject<
    SettingItem[]
  >(this.registeredSettings);

  constructor(
    private readonly injector: Injector,
    private readonly theme: ThemeService,
    private readonly translation: TranslateService,
    private readonly authService: AuthService
  ) {}

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  private executeAndClose(action: (injector: Injector) => void): () => void {
    return () => {
      action(this.injector);
      this.closeSettings();
    };
  }

  public openSettings(): void {
    this.settingsOpened$.next(true);
  }

  public closeSettings(): void {
    this.settingsOpened$.next(false);
  }

  registerSetting(item: SettingItem): number {
    return (
      this.registeredSettings.push({
        ...item,
        action: this.executeAndClose(item.action ?? (() => {}))
      }) - 1
    );
  }

  unRegisterSetting(item: number): void {
    this.registeredSettings.splice(item, 1);
  }
}
