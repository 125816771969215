import { SidenavItem } from './sidebar.service';

export default [
  {
    id: 'integrations',
    label: 'menu.entry.integrations.title',
    icon: 'knkList' as const,
    children: [
      {
        id: 'methods',
        label: 'menu.entry.integrations.methods',
        route: 'methods',
        icon: 'knkList' as const
      },
      {
        id: 'methods/in-source',
        label: 'menu.entry.integrations.methodsInSource',
        route: 'method-sources',
        icon: 'knkList' as const
      }
    ]
  },
  {
    id: 'data-base',
    label: 'menu.entry.database.title',
    icon: 'knkCustomerCard' as const,
    children: [
      {
        id: 'anonymousUsers',
        label: 'menu.entry.database.anonymousUsers',
        route: 'anonymous-users',
        icon: 'knkCustomerCard' as const
      },
      {
        id: 'users',
        label: 'menu.entry.database.users',
        icon: 'knkCustomerCard' as const,
        route: 'users'
      }
    ]
  },
  {
    id: 'marketingCampaigns',
    label: 'menu.entry.marketingCampaigns.title',
    icon: 'knkChoose' as const,
    children: [
      {
        id: 'campaigns',
        label: 'menu.entry.marketingCampaigns.campaigns',
        icon: 'knkList' as const,
        route: 'campaigns'
      },
      {
        id: 'receipts',
        label: 'menu.entry.receipts',
        icon: 'knkCheck' as const,
        route: 'receipts'
      },
      {
        id: 'coupons',
        label: 'menu.entry.coupons',
        icon: 'knkCertificate' as const,
        children: [
          {
            id: 'section',
            label: 'menu.entry.coupons',
            route: 'coupons',
            icon: 'knkList' as const
          },
          {
            id: 'coupon-generators',
            label: 'menu.entry.couponGenerators',
            route: 'coupons/generators',
            icon: 'knkWait' as const
          }
        ]
      },
      {
        id: 'loyalty-programs',
        label: 'menu.entry.loyaltyPrograms',
        route: 'loyalty-promos',
        icon: 'knkList' as const
      }
    ]
  },
  {
    id: 'advertisingCampaigns',
    label: 'menu.entry.advertisingCampaigns.title',
    icon: 'knkList' as const,
    children: [
      {
        id: 'cabinets',
        label: 'menu.entry.advertisingCampaigns.cabinets',
        route: 'cabinets',
        icon: 'knkList' as const
      },
      {
        id: 'jobs',
        label: 'menu.entry.advertisingCampaigns.media',
        route: 'cabinets/jobs',
        icon: 'knkList' as const
      }
    ]
  },
  {
    id: 'pixels',
    label: 'menu.entry.pixels.title',
    route: 'pixels',
    icon: 'knkList' as const
  },
  {
    id: 'schemas',
    label: 'menu.entry.marketingCampaigns.campaignSchemas',
    route: 'campaign-schemas',
    icon: 'knkFlow' as const
  },
  {
    id: 'segments',
    label: 'menu.entry.segments.title',
    icon: 'knkSegments' as const,
    route: 'segments'
  },
  {
    id: 'communications',
    label: 'menu.entry.communication.title',
    icon: 'knkCommunication' as const,
    route: 'communications'
  },
  {
    id: 'yandex-gpt',
    label: 'menu.entry.gpt.title',
    icon: 'knkAi' as const,
    route: 'yandex-gpt'
  },
  {
    id: 'calendar',
    label: 'menu.entry.calendar',
    route: 'calendar',
    icon: 'knkClock' as const
  },
  {
    id: 'administration',
    label: 'menu.entry.administration',
    icon: 'knkSettingsVertical' as const,
    children: [
      {
        id: 'roles',
        label: 'menu.entry.roles',
        route: 'acs/roles',
        icon: 'knkExtension' as const
      },
      {
        id: 'operator-roles',
        label: 'menu.entry.operatorRoles',
        route: 'acs/applied-roles',
        icon: 'knkExtension' as const
      },
      {
        id: 'lookups',
        label: 'menu.entry.lookups',
        icon: 'knkSearch' as const,
        children: [
          {
            id: 'user-type',
            label: 'menu.entry.lookupsUserType',
            route: 'lookups/user-type',
            icon: 'knkSearch' as const
          },
          {
            id: 'user-status',
            label: 'menu.entry.lookupsUserStatus',
            route: 'lookups/user-status',
            icon: 'knkSearch' as const
          },
          {
            id: 'identifier-type',
            label: 'menu.entry.lookupsIdentifierType',
            route: 'lookups/identifier-types',
            icon: 'knkSearch' as const
          },
          {
            id: 'unsubscribe',
            label: 'menu.entry.lookupsUnsubscribe',
            route: 'lookups/unsubscribe',
            icon: 'knkSearch' as const
          },
          {
            id: 'ticket-generator',
            label: 'menu.entry.ticketGenerators',
            route: 'lookups/ticket-generators',
            icon: 'knkSearch' as const
          },
          {
            id: 'bonus-wallet',
            label: 'menu.entry.lookupsBonusWallet',
            route: 'lookups/bonus-wallet',
            icon: 'knkSearch' as const
          },
          {
            id: 'custom-event',
            label: 'menu.entry.lookupsCustomEvents',
            route: 'lookups/custom-event',
            icon: 'knkSearch' as const
          },
          {
            id: 'receipt-status',
            label: 'menu.entry.lookupsReceiptStatus',
            route: 'lookups/receipt-status',
            icon: 'knkSearch' as const
          }
        ]
      },
      {
        id: `superset-dashboard-settings`,
        label: 'SupersetDashboards',
        route: `dashboard-settings`,
        icon: 'knkList'
      }
    ]
  }
] as SidenavItem[];
