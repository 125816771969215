import { DestroyRef, Injectable, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Router } from '@angular/router';
import {
  BehaviorSubject,
  Observable,
  combineLatest,
  isObservable,
  map
} from 'rxjs';
import { MENU_ITEMS, MENU_MAIN_PATH } from '../menu-items.token';
import DEFAULT_ITEMS from './default-items';

export interface SidenavItem {
  id: string;
  label: string;
  icon?: string;
  route?: string;
  children?: SidenavItem[];
}

@Injectable({ providedIn: 'root' })
export class SidebarService {
  private readonly router = inject(Router);

  private readonly mainPath =
    inject(MENU_MAIN_PATH, { optional: true }) || 'users';

  private readonly destroyRef = inject(DestroyRef);

  private readonly itemsFromDi = inject(MENU_ITEMS, { optional: true });

  readonly items$ = new BehaviorSubject<SidenavItem[]>(DEFAULT_ITEMS);

  isSidenavCollapsed$ = new BehaviorSubject(false);

  isSidenavExpanded$ = this.isSidenavCollapsed$.pipe(
    map((collapsed) => !collapsed)
  );

  currentMenuPath$ = new BehaviorSubject<string[]>([]);

  currentMenuItems$ = combineLatest([this.currentMenuPath$, this.items$]).pipe(
    map(([currentPathArray, items]) => {
      const path = [...currentPathArray];
      let current = items;
      while (path.length > 0) {
        const part = path.shift();
        current = current.find((i) => i.id === part)?.children ?? items;
      }
      return { items: current, path: currentPathArray };
    })
  );

  constructor() {
    if (this.itemsFromDi) {
      this.initializeItems(this.itemsFromDi);
    }
  }

  private initializeItems(
    items: SidenavItem[] | Observable<SidenavItem[]>
  ): void {
    if (isObservable(items)) {
      items.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((menuItems) => {
        this.items$.next(menuItems);
      });
    } else {
      this.items$.next(items);
    }
  }

  menuCollapse(): void {
    this.isSidenavCollapsed$.next(true);
  }

  menuExpand() {
    this.isSidenavCollapsed$.next(false);
  }

  menuToggleCollapse() {
    this.isSidenavCollapsed$.next(!this.isSidenavCollapsed$.getValue());
  }

  menuOpenItem(id: string) {
    this.currentMenuPath$.next([...this.currentMenuPath$.getValue(), id]);
  }

  menuBack() {
    const menuPath = [...this.currentMenuPath$.getValue()];
    menuPath.pop();
    this.currentMenuPath$.next(menuPath);
  }

  menuClearPath() {
    this.currentMenuPath$.next([]);
  }

  menuCreateOrUpdateItem({
    item,
    toIndex,
    parentPath = []
  }: {
    item: SidenavItem;
    toIndex?: number;
    parentPath?: string[];
  }) {
    const rootItems = [...this.items$.getValue()];
    let pushTo = rootItems;
    if (parentPath) {
      const path = [...parentPath];
      let part = parentPath.shift();
      pushTo = pushTo.find((i) => i.id === part)?.children ?? pushTo;
      while (path.length > 0) {
        part = path.shift();
        pushTo = pushTo.find((i) => i.id === part)?.children ?? pushTo;
      }
      if (!pushTo) {
        throw new Error('Parent item not found');
      }
    }
    const modifyingItemIndex = pushTo.findIndex((i) => i.id === item.id);
    if (modifyingItemIndex !== -1) {
      pushTo[modifyingItemIndex] = item;
    } else {
      if (toIndex !== undefined) {
        pushTo.splice(toIndex, 0, item);
      } else {
        pushTo.push(item);
      }
    }
    this.items$.next(rootItems);
  }

  redirectToMain() {
    this.menuClearPath();
    void this.router.navigateByUrl(this.mainPath);
  }
}
