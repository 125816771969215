import { DestroyRef, inject, Injectable, signal } from '@angular/core';

export function disableLayoutPadding() {
  const layoutService = inject(LayoutService);
  const destroyRef = inject(DestroyRef);

  layoutService.isInnerPaddingEnabled.update(() => false);

  destroyRef.onDestroy(() =>
    layoutService.isInnerPaddingEnabled.update(() => true)
  );
}

@Injectable({ providedIn: 'root' })
export class LayoutService {
  readonly isInnerPaddingEnabled = signal(true);
}
